<template>
  <div class="row">
    <input-text
      v-model="filtro.nome"
      class="col-12"
      :label="$t('modulos.tipo_ocorrencia.filtro.nome')"
      :placeholder="$t('modulos.tipo_ocorrencia.filtro.nome')"
      em-filtro
    />
  </div>
</template>
<script>
export default {
  props: {
    filtro: {},
  },
  data() {
    return { }

  },
};
</script>
